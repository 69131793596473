<template>
    <div class="report-index">
        <div class="left">
            <md-list>
                <md-list-item md-expand-multiple ref="menuitem">
                    <router-link to="/admin/reports/booking-locations">
                        <md-icon>people</md-icon>
                        <span>Booking Locations</span>
                    </router-link>
                </md-list-item>
                <md-list-item md-expand-multiple ref="menuitem" v-if="showMonthlyBookings">
                    <router-link to="/admin/reports/monthly-bookings">
                        <md-icon>people</md-icon>
                        <span>Monthly Bookings</span>
                    </router-link>
                </md-list-item>
                <md-list-item md-expand-multiple ref="menuitem">
                    <router-link to="/admin/reports/product-sales">
                        <md-icon>people</md-icon>
                        <span>Product Sales</span>
                    </router-link>
                </md-list-item>
                <md-list-item md-expand-multiple ref="menuitem" v-if="hasRole('manager')">
                    <router-link to="/admin/reports/search-words">
                        <md-icon>people</md-icon>
                        <span>Search Results</span>
                    </router-link>
                </md-list-item>
                <md-list-item md-expand-multiple ref="menuitem" v-if="showVendorTotals">
                    <router-link to="/admin/reports/vendor-booking-totals">
                        <md-icon>people</md-icon>
                        <span>Vendor Totals</span>
                    </router-link>
                </md-list-item>
            </md-list>
        </div>
        <div class="right">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            asRole: "user/asRole"
        }),
        showMonthlyBookings() {
            if (this.asRole("manager")) {
                return this.user.report_permissions && this.user.report_permissions["view_monthly_bookings"] ? true : false;
            } else {
                return true;
            }
        },
        showVendorTotals() {
            if (this.hasRole("manager")) {
                if (this.asRole("manager")) {
                    return this.user.report_permissions && this.user.report_permissions["view_vendor_totals"] ? true : false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    }
};
</script>
<style lang="less">
.report-index {
    display: flex;
    flex-wrap: wrap;

    .left {
        width: 220px;
    }

    .right {
        flex: 1;
        height: 100%;
        padding-top: 10px;
    }
}

@media (max-width: 600px) {
    .report-index {
        .left {
            display: none;
        }
    }
}
</style>
